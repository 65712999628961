import React from "react";
import "./index.css";
import { CssVarsProvider } from "@mui/joy/styles";

import Typography from "@mui/joy/Typography";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Link from "@mui/joy/Link";
import Header from "../../components/appbar";
import Layout from "../../components/layout";
import BottomBar from "../../components/bottombar";

import lang from "../../language/lang.js";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

function App() {
  var curlang = Cookies.get("lang");
  if (curlang === undefined) {
    curlang = "en";
  }
  const [language, setLanguage] = React.useState(
    curlang === "en" ? lang.en : lang.cn
  );

  const onChangeLang = (newlang) => {
    //console.log("Language changed to", newlang);
    if (newlang === "cn") {
      setLanguage(lang.cn);
    } else {
      setLanguage(lang.en);
    }
  };

  const navigate = useNavigate();

  return (
    <CssVarsProvider>
      <main>
        <CssBaseline />
        <BottomBar />
        <Layout.Header>
          {" "}
          <Header onChangeLanguage={onChangeLang} />
        </Layout.Header>
        <Box className="App-header">
          <Typography level="h1">{language.Welcome}</Typography>
          <Link
            onClick={() => {
              navigate("/join/");
            }}
            level="h3"
          >
            {language.ClickRTC}
          </Link>
          <Link
            onClick={() => {
              navigate("/vision/");
            }}
            level="h3"
          >
            {language.ClickVision}
          </Link>
        </Box>
      </main>
    </CssVarsProvider>
  );
}

export default App;
