import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Stack } from "@mui/joy";

import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";

function BottomBar(props) {
  const navigate = useNavigate();
  //const [language, setLanguage] = React.useState(props.language);

  return (
    <Stack
      id="tab-bar"
      direction="row"
      spacing={1}
      sx={{
        justifyContent: "space-around",
        display: { xs: "flex", sm: "none" },
        zIndex: "999",
        bottom: 0,
        position: "fixed",
        width: "100dvw",
        py: 2,
        backgroundColor: "background.body",
        borderTop: "1px solid",
        borderColor: "divider",
      }}
    >
      <Button
        variant="plain"
        color="neutral"
        component="a"
        onClick={() => navigate("/")}
        size="sm"
        startDecorator={<EmailRoundedIcon />}
        sx={{ flexDirection: "column", "--Button-gap": 0 }}
      >
        Home
      </Button>
      <Button
        variant="plain"
        color="neutral"
        aria-pressed="true"
        component="a"
        onClick={() => navigate("/join/")}
        size="sm"
        startDecorator={<PeopleAltRoundedIcon />}
        sx={{ flexDirection: "column", "--Button-gap": 0 }}
      >
        RTC
      </Button>
      <Button
        variant="plain"
        color="neutral"
        component="a"
        onClick={() => navigate("/vision/")}
        size="sm"
        startDecorator={<FolderRoundedIcon />}
        sx={{ flexDirection: "column", "--Button-gap": 0 }}
      >
        Vision
      </Button>
    </Stack>
  );
}

export default BottomBar;
