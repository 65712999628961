import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./pages/PageApp";
import PageJoin from "./pages/PageJoin";
import PageRoom from "./pages/PageRoom";
import PageVision from "./pages/PageVision";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/join/" element={<PageJoin />} />
        <Route path="/vision/" element={<PageVision />} />
        <Route path="/room/:token" element={<PageRoom />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
