import React from "react";
import "./index.css";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Header from "../../components/appbar";
import Layout from "../../components/layout";
import Button from "@mui/joy/Button";
import BottomBar from "../../components/bottombar";

import { Divider, Typography } from "@mui/joy";
import Textarea from "@mui/joy/Textarea";
import Link from "@mui/joy/Link";

import lang from "../../language/lang.js";
import Cookies from "js-cookie";

function PageVision() {
  const [answer, setAnswer] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [isAsking, setIsAsking] = useState(false);
  const fileInputRef = useRef(null);

  var curlang = Cookies.get("lang");
  if (curlang === undefined) {
    curlang = "en";
  }
  const [language, setLanguage] = React.useState(
    curlang === "en" ? lang.en : lang.cn
  );
  const [prompt, setPrompt] = useState(language.InputPrompt);
  const onChangeLang = (newlang) => {
    //console.log("Language changed to", newlang);
    if (newlang === "cn") {
      setLanguage(lang.cn);
      setPrompt(lang.cn.InputPrompt);
    } else {
      setLanguage(lang.en);
      setPrompt(lang.en.InputPrompt);
    }
  };

  const navigate = useNavigate();

  const changePrompt = (e) => {
    setPrompt(e.target.value);
  };

  const changeAnswer = (e) => {
    setAnswer(e.target.value);
  };

  const uploadImg = () => {
    fileInputRef.current.click(); // 触发文件输入的点击事件
  };

  const backHome = () => {
    navigate("/");
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file); // 将文件添加到 FormData

      try {
        setIsUploading(true);
        const response = await axios.post(
          "https://xapienai.com/api/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // 设置请求头
            },
          }
        );
        console.log("Upload successful:", response.data.data.fileKey);
        // fileKey + fileName
        // 这里用 fileKey 去换 URL，然后显示在页面上
        const urlres = await axios.post(
          "https://xapienai.com/api/geturl",
          { fileKey: response.data.data.fileKey },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setIsUploading(false);
        console.log("Get URL successful:", urlres.data);
        setImageSrc(urlres.data.data.fileURL);
      } catch (error) {
        setIsUploading(false);
        console.error("Error uploading file:", error);
      }
    }
  };

  const askGPT = async () => {
    try {
      setIsAsking(true);
      const response = await axios.post(
        "https://xapienai.com/api/callgpt_vision",
        {
          model: "gpt-4o-mini",
          question: prompt,
          imgurl: imageSrc,
          detail: "high",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //console.log("Ask GPT Vision successful:", response.data.data.answer);
      setIsAsking(false);
      setAnswer(response.data.data.answer);
    } catch (error) {
      console.error("Error asking GPT:", error);
      setIsAsking(false);
      setAnswer("Error asking GPT: " + error);
    }
  };

  return (
    <CssVarsProvider>
      <main>
        <CssBaseline />
        <BottomBar />
        <Layout.Header>
          {" "}
          <Header onChangeLanguage={onChangeLang} />
        </Layout.Header>
        <Box className="Main">
          <Button
            onClick={uploadImg}
            loading={isUploading}
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            {language.UploadImg}
          </Button>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          {imageSrc && (
            <img
              src={imageSrc}
              alt="Uploaded"
              style={{ maxWidth: "500px", maxHeight: "500px" }}
            />
          )}
          <Divider sx={{ marginTop: "20px" }} />
          <Typography className="AskPrompt" sx={{ marginTop: "30px" }}>
            {language.AskPrompt}
          </Typography>
          <Textarea
            id="question"
            value={prompt}
            onChange={changePrompt}
            className="AskInput"
            minRows={3}
            sx={{
              marginTop: "20px",
            }}
          />

          <Button
            onClick={askGPT}
            loading={isAsking}
            disabled={imageSrc === ""}
            sx={{ marginTop: "10px" }}
          >
            {language.AskGPT}
          </Button>
          <Typography className="AnswerPrompt" sx={{ marginTop: "20px" }}>
            {language.AnswerPrompt}
          </Typography>
          <Textarea
            id="answertext"
            onChange={changeAnswer}
            value={answer}
            className="AnswerInput"
            minRows={5}
          />
          <Divider sx={{ marginTop: "20px" }} />
          <Link
            onClick={backHome}
            sx={{ marginTop: "20px", marginBottom: "40px" }}
          >
            {language.BackHome}
          </Link>
        </Box>
      </main>
    </CssVarsProvider>
  );
}

export default PageVision;
