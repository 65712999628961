import React from "react";
//import { useNavigate } from "react-router-dom";
import "./index.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Header from "../../components/appbar";
import Layout from "../../components/layout";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import { Typography } from "@mui/joy";
import BottomBar from "../../components/bottombar";

import lang from "../../language/lang.js";
import Cookies from "js-cookie";

function PageJoin() {
  const [room, setRoom] = useState("room001");
  const [user, setUser] = useState("Jane");

  var curlang = Cookies.get("lang");
  if (curlang === undefined) {
    curlang = "en";
  }
  const [language, setLanguage] = React.useState(
    curlang === "en" ? lang.en : lang.cn
  );

  const navigate = useNavigate();

  const onChangeLang = (newlang) => {
    //console.log("Language changed to", newlang);
    if (newlang === "cn") {
      setLanguage(lang.cn);
    } else {
      setLanguage(lang.en);
    }
  };

  const changeRoom = (e) => {
    setRoom(e.target.value);
  };

  const changeUser = (e) => {
    setUser(e.target.value);
  };

  const joinRoom = () => {
    console.log("join room", room, user);
    fetch("https://xapienai.com/api/gettoken", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        roomID: room,
        userID: user,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        navigate(`/room/${data.data.token}`);
      });
  };

  return (
    <CssVarsProvider>
      <main>
        <CssBaseline />
        <BottomBar />
        <Layout.Header>
          {" "}
          <Header onChangeLanguage={onChangeLang} />
        </Layout.Header>
        <Box className="Main">
          <Typography sx={{ margin: "10px" }}>{language.RoomName}</Typography>
          <Input
            sx={{ margin: "10px" }}
            id="room"
            type="text"
            value={room}
            onChange={changeRoom}
          />
          <Typography sx={{ margin: "10px" }}>{language.UserName}</Typography>
          <Input
            sx={{ margin: "10px" }}
            id="user"
            type="text"
            value={user}
            onChange={changeUser}
          />
          <Button
            sx={{ margin: "10px" }}
            className="JoinButton"
            onClick={joinRoom}
          >
            {language.JoinRoom}
          </Button>
        </Box>
      </main>
    </CssVarsProvider>
  );
}

export default PageJoin;
