const lang_cn = {
  SearchAny: "搜索任何东西...",
  Home: "首页",
  RTC: "会议",
  Vision: "视觉",
  Welcome: "欢迎来到 Xapien!",
  ClickRTC: "点击这里使用我们的 RTC",
  ClickVision: "点击这里使用视觉模型",
  UploadImg: "上传图像",
  AskPrompt: "问一个问题",
  AskGPT: "问 GPT",
  InputPrompt: "图里有什么？",
  AnswerPrompt: "GPT 回答",
  BackHome: "返回首页",
  RoomName: "房间名",
  UserName: "用户名",
  JoinRoom: "加入房间",
  Help: "帮助",
  Settings: "设置",
  Logout: "登出",
};

const lang_en = {
  SearchAny: "Search anything...",
  Home: "Home",
  RTC: "RTC",
  Vision: "Vision",
  Welcome: "Welcome to Xapien!",
  ClickRTC: "Click HERE to Use our RTC",
  ClickVision: "Click HERE to Use Vision Model",
  UploadImg: "Upload a Image",
  AskPrompt: "Ask a quesion",
  AskGPT: "Ask GPT",
  InputPrompt: "What's in the image?",
  AnswerPrompt: "GPT Answer",
  BackHome: "Back to Home",
  RoomName: "Room Name",
  UserName: "User Name",
  JoinRoom: "Join Room",
  Help: "Help",
  Settings: "Settings",
  Logout: "Logout",
};

const lang = {
  cn: lang_cn,
  en: lang_en,
};

export default lang;
