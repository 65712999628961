import * as React from "react";
import { useState } from "react";
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";

import Avatar from "@mui/joy/Avatar";
import Menu from "@mui/joy/Menu";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Dropdown from "@mui/joy/Dropdown";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import ListDivider from "@mui/joy/ListDivider";

import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import IconButton from "@mui/joy/IconButton";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";

import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import DarkButton from "../darkbutton";
import lang from "../../language/lang.js";

export default function Header({ onChangeLanguage }) {
  const [key, setKey] = useState(0);
  const navigate = useNavigate();

  var curlang = Cookies.get("lang");
  if (curlang === undefined) {
    curlang = "en";
  }
  const [language, setLanguage] = React.useState(
    curlang === "en" ? lang.en : lang.cn
  );
  const setEnglish = () => {
    //console.log("setEnglish");
    Cookies.set("lang", "en");
    setLanguage(lang.en);
    onChangeLanguage("en");
    setKey((prevKey) => prevKey + 1);
  };

  const setChinese = () => {
    //console.log("setChinese");
    Cookies.set("lang", "cn");
    setLanguage(lang.cn);
    onChangeLanguage("cn");
    setKey((prevKey) => prevKey + 1);
  };

  return (
    <Box sx={{ display: "flex", flexGrow: 1, justifyContent: "space-between" }}>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: { xs: "none", sm: "flex" },
        }}
      >
        <img
          src="../newlogo.png"
          alt="logo"
          style={{ width: "50px", marginRight: "10px" }}
        />
        <Button
          variant="plain"
          color="neutral"
          component="a"
          onClick={() => navigate("/")}
          size="md"
          sx={{ alignSelf: "center" }}
        >
          {language.Home}
        </Button>
        <Button
          variant="plain"
          color="neutral"
          component="a"
          onClick={() => navigate("/join/")}
          size="md"
          sx={{ alignSelf: "center" }}
        >
          {language.RTC}
        </Button>
        <Button
          variant="plain"
          color="neutral"
          component="a"
          onClick={() => navigate("/vision/")}
          size="md"
          sx={{ alignSelf: "center" }}
        >
          {language.Vision}
        </Button>
      </Stack>
      <Box sx={{ display: { xs: "inline-flex", sm: "none" } }}>
        {" "}
        <img
          src="../newlogo.png"
          alt="logo"
          style={{ width: "50px", marginRight: "10px" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1.5,
          alignItems: "center",
        }}
      >
        <Input
          size="sm"
          key={key}
          variant="outlined"
          placeholder={language.SearchAny}
          startDecorator={<SearchRoundedIcon color="primary" />}
          endDecorator={
            <IconButton
              variant="outlined"
              color="neutral"
              sx={{ bgcolor: "background.level1" }}
            >
              <Typography level="title-sm" textColor="text.icon">
                ⌘ K
              </Typography>
            </IconButton>
          }
          sx={{
            alignSelf: "center",
            display: {
              xs: "none",
              sm: "flex",
            },
          }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          sx={{
            display: { xs: "inline-flex", sm: "none" },
            alignSelf: "center",
          }}
        >
          <SearchRoundedIcon />
        </IconButton>
        <Dropdown>
          <MenuButton
            slots={{ root: IconButton }}
            slotProps={{ root: { variant: "outlined", color: "neutral" } }}
          >
            <LanguageRoundedIcon />
          </MenuButton>

          <Menu sx={{ zIndex: 1200 }}>
            <MenuItem onClick={setEnglish}>English</MenuItem>
            <MenuItem onClick={setChinese}>简体中文</MenuItem>
          </Menu>
        </Dropdown>
        <DarkButton />
        <Dropdown>
          <MenuButton
            variant="plain"
            size="sm"
            sx={{
              maxWidth: "32px",
              maxHeight: "32px",
              borderRadius: "9999999px",
            }}
          >
            <Avatar
              src="https://i.pravatar.cc/40?img=2"
              srcSet="https://i.pravatar.cc/80?img=2"
              sx={{ maxWidth: "32px", maxHeight: "32px" }}
            />
          </MenuButton>
          <Menu
            placement="bottom-end"
            size="sm"
            sx={{
              zIndex: "99999",
              p: 1,
              gap: 1,
              "--ListItem-radius": "var(--joy-radius-sm)",
            }}
          >
            <MenuItem>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  src="https://i.pravatar.cc/40?img=2"
                  srcSet="https://i.pravatar.cc/80?img=2"
                  sx={{ borderRadius: "50%" }}
                />
                <Box sx={{ ml: 1.5 }}>
                  <Typography level="title-sm" textColor="text.primary">
                    Rick Sanchez
                  </Typography>
                  <Typography level="body-xs" textColor="text.tertiary">
                    rick@email.com
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
            <ListDivider />
            <MenuItem>
              <HelpRoundedIcon />
              {language.Help}
            </MenuItem>
            <MenuItem>
              <SettingsRoundedIcon />
              {language.Settings}
            </MenuItem>
            <ListDivider />
            <MenuItem>
              <LogoutRoundedIcon />
              {language.Logout}
            </MenuItem>
          </Menu>
        </Dropdown>
      </Box>
    </Box>
  );
}
