import {
  ControlBar,
  GridLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useTracks,
} from "@livekit/components-react";

import "@livekit/components-styles";

import { Track } from "livekit-client";
import { useParams } from "react-router-dom";
import "./index.css";
import { useEffect } from "react";

const serverUrl = "wss://xp-ob0lmjxp.livekit.cloud";
//const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3Mjk3NjA0ODUsImlzcyI6IkFQSWs3OHdDb0RjOFhqWCIsIm5iZiI6MTcyOTc1Njg4NSwic3ViIjoidXNlcjAwMSIsInZpZGVvIjp7ImNhblB1Ymxpc2giOnRydWUsImNhblN1YnNjcmliZSI6dHJ1ZSwicm9vbSI6InJvb20wMDEiLCJyb29tSm9pbiI6dHJ1ZX19.8yRZg4TSjUoQAH9nBMKbncRTjG_J0ApyVOr_B2ykiyY";
// 10-minute expiration

function PageRoom() {
  const { token } = useParams();
  useEffect(() => {
    console.log("token", token);
  }, [token]);

  return (
    <LiveKitRoom
      video={true}
      audio={true}
      token={token}
      serverUrl={serverUrl} //{process.env.NEXT_PUBLIC_LK_SERVER_URL}
      // Use the default LiveKit theme for nice styles.
      data-lk-theme="default"
      style={{ height: "100vh", width: "100vw" }}
    >
      {/* Your custom component with basic video conferencing functionality. */}
      <MyVideoConference />
      {/* The RoomAudioRenderer takes care of room-wide audio for you. */}
      <RoomAudioRenderer />
      {/* Controls for the user to start/stop audio, video, and screen
      share tracks and to leave the room. */}
      <ControlBar />
    </LiveKitRoom>
  );
}

function MyVideoConference() {
  // `useTracks` returns all camera and screen share tracks. If a user
  // joins without a published camera track, a placeholder track is returned.
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false }
  );
  return (
    <GridLayout
      tracks={tracks}
      style={{ height: "calc(100vh - var(--lk-control-bar-height))" }}
    >
      {/* The GridLayout accepts zero or one child. The child is used
      as a template to render all passed in tracks. */}
      <ParticipantTile />
    </GridLayout>
  );
}

export default PageRoom;
